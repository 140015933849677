import { useEffect, useState } from "react";
import ApiService from "../../common/ApiService";
import { RotateLoader } from "react-spinners";

export default function Incubators({ sectorSno }) {
    const apiService = new ApiService();
    const [incubatorsList, setIncubatorsList] = useState(null);
    console.log(sectorSno)
    useEffect(() => {
        getIncuators();
    }, [sectorSno])

    async function getIncuators() {
        setIncubatorsList(null)
        var body = { 'sectorSno': sectorSno, 'skip': 0, "limit": 10 };
        console.log(body)
        try {
            const response = await apiService.post('get_incubators', body);
            console.log(response)
            if (response && response?.data) {
                setIncubatorsList(response?.data)
            } else {
                setIncubatorsList([])
            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }



    return (
        <>
            <br></br>
            {(incubatorsList != null && incubatorsList?.length > 0) ? (<section className="wrapper">
                <div className="container-fostrap">
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                {incubatorsList?.map((item, index) => (
                                    <div className="col-xs-12 col-sm-4" key={index}>
                                        <div className="card">
                                            <img src={item?.contact_info?.img} />
                                            <div className="card-content p-2">
                                                <h4 className="card-title">
                                                    <div className="d-flex justify-content-between">
                                                        <a  > {item?.name} </a>
                                                        <div className="my-1" style={{ fontSize: '15px' }}>{item?.contact_info?.contactInfo}</div>

                                                    </div>
                                                    <div className="m-1">
                                                        <div className="">
                                                            {/* <div className="my-1" style={{fontSize:'15px'}}>{item?.contact_info?.contactInfo}</div> */}
                                                            <div className="my-1" style={{ fontSize: '15px' }}>{item?.location}</div>
                                                        </div>
                                                    </div>
                                                </h4>
                                                <p className="">
                                                    Number Of availability {item?.capacity}
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>
                    </div>
                </div>
            </section>):(incubatorsList && incubatorsList.length == 0) ? (<div className="text-center my-4">
                <img src="/assets/no data/images.png" alt="" width={'300px'} />
                <div className="h6 text-secondary">Incubators Not Found !</div>
            </div>) : (<div className="my-5 text-center ">
                <br></br>
                <RotateLoader color="#212529" />
            </div>)}

        </>
    );
}