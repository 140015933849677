import { Chip, Stack, TextField } from '@mui/material';
import '../../pages/StartUp/startup.css'
import { IoSearch } from "react-icons/io5";
import { useEffect, useState } from 'react';
import ApiService from '../../common/ApiService';
import { useNavigate } from 'react-router-dom';
import { debounce } from '@material-ui/core';
import { FaLocationDot } from "react-icons/fa6";
import bglogo from '../../assets/user-card/stn-avtar.png'
import ViewStartupDialoge from '../../common/View-StartUp-Dialoge';

export default function Startup(props) {
    console.log(props)
    const apiService = new ApiService();
    const [searchValue, setSearchValue] = useState(props?.params?.searchKey);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const handleClickOpen = (item) => {
        console.log(item)
        setSelectedItem(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSearchChange = (event) => {
        console.log(event.target.value)
        setSearchValue(event.target.value)
        props.keyFunction({ "searchKey": event.target.value })
        debounceSearchApiCall(event.target.value)
    };
    const debounceSearchApiCall = debounce((searchQuery) => {

        console.log(searchQuery)

        // fetchData(searchQuery, props?.sectorSno?.sectorSno, props?.district?.districtSno);

        console.log('Making API call with search query:', searchQuery);
    }, 300);



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize); // Listen for resize events

        return () => {
            window.removeEventListener('resize', handleResize); // Clean up the event listener
        };
    }, [props]);

    const goToViewSector = (sector) => {
        navigate("/View-Sector", { state: sector })
    }
    const handleClick = () => {
        console.info('You clicked the Chip.');
    };

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
        props.sectorFunction({ "sectorSno": null, "sectorName": null })

    };
    const handleDelete1 = () => {
        console.info('You clicked the delete icon.');
        props.districtFunction({ "districtSno": null, "districtName": null })

    };

    const handleDelete2 = () => {
        console.info('You clicked the delete icon.');
        setSearchValue('');
        props.keyFunction({ "searchKey": '' })

    };
    return (
        <>
            {/* <Stack direction="row" spacing={1}>
                <Chip sx={{borderRadius:'5px',backgroundColor:'#ecdff4',color:'#4a007e'}}
                    label={searchValue}
                    onClick={handleClick}
                    onDelete={handleDelete}
                />
                <Chip sx={{borderRadius:'5px',backgroundColor:'#ecdff4',color:'#4a007e'}}
                    label={}
                    variant="outlined"
                    onClick={handleClick}
                    onDelete={handleDelete}
                />
            </Stack> */}
            <div className=" ">
                <TextField className='mx-2'
                    fullWidth
                    id="standard-basic"
                    placeholder="Search By Startup..."
                    value={searchValue}
                    onChange={handleSearchChange}
                    sx={{
                        mt: 1,
                        '& fieldset': {
                            borderColor: '#f2f3f5', // Change border color to light grey
                        },
                        '&:hover fieldset': {
                            borderColor: '#f2f3f5', // Add hover effect
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#f2f3f5', // Add focus effect
                        },
                        pb: 2, // Add padding bottom
                    }}
                    InputProps={{
                        startAdornment: (
                            <IoSearch style={{ marginRight: '8px', color: '#757575' }} />
                        ),
                    }}
                />
                <div>
                    <div className='mx-2'>
                        {props?.params?.sectorName != null || props?.params?.districtName != null || (props?.params?.searchKey != null && props?.params?.searchKey?.trim() != '') ? (
                            <div className='h6'>Applied Fillters</div>) : (<></>)}
                        <Stack direction={'row'}>
                            {props?.params?.sectorName != null ?
                                (<Chip label={props?.params?.sectorName} variant="outlined" onDelete={handleDelete}
                                    sx={{ backgroundColor: '#ddc4f6', border: 'none', borderRadius: '8px', mx: 1 }} />) : (<></>)}
                            {props?.params?.districtName != null ? (<Chip label={props?.params?.districtName} variant="outlined" onDelete={handleDelete1}
                                sx={{ backgroundColor: '#fbd2c4', border: 'none', borderRadius: '8px', mx: 1 }} />) : (<></>)}
                            {(props?.params?.searchKey != null && props?.params?.searchKey?.trim() != '') ? (<Chip label={props?.params?.searchKey} variant="outlined" onDelete={handleDelete2}
                                sx={{ backgroundColor: '#ddc4f6', border: 'none', borderRadius: '8px' }} />) : (<></>)}
                        </Stack>
                    </div>
                    {props?.startupList?.length > 0 ? (<>
                        {props?.startupList?.map((item, index) => (
                            <div className="card m-3" key={index}>
                                <div className="col-12">
                                    <div className={isMobile ? "" : "ag-courses_box mt-4"}>
                                        <div className="ag-courses_item" onClick={() => goToViewSector(item)}>
                                            <img src={item?.bannerImage} className="card-img-top" alt="..." style={{ minHeight: '140px', borderRadius: '16px 16px 0px 0px' }} />
                                            <div className="py-3 text-center text-white h6 mb-0" style={{ background: '#38157c', borderRadius: '0px 0px 16px 16px' }}>
                                                {item?.sectorName} Sector
                                                <div className=' h6 py-2 text-center' style={{ color: '#f9b234' }} onClick={() => goToViewSector(item)}>View Sector</div>

                                            </div>
                                        </div>

                                        {item?.startupList?.map((startup) => (
                                            <div className="ag-courses_item" key={startup?.entitySno} onClick={() => handleClickOpen(startup)}>
                                                <a className="ag-courses-item_link">
                                                    <div className="ag-courses-item_bg"></div>

                                                    <div className="ag-courses-item_title truncate">
                                                        <img className='p-1 rounded-circle' src={startup?.logoPath} alt="" width={40} height={40} onError={(e) => {
                                                            e.target.src = bglogo; // Replace with fallback image when error occurs
                                                            e.target.onerror = null; // Prevent infinite loop in case fallback image also fails
                                                        }} />
                                                        {startup?.entityName}
                                                    </div>

                                                    <div className="ag-courses-item_date-box">
                                                        <FaLocationDot style={{ color: '#38157c' }} />
                                                        <span className="ag-courses-item_date px-2">
                                                            {startup?.districtName}
                                                        </span>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}

                                    </div>
                                    <div className='text-end p-3 text-primary h6'
                                        onClick={() => navigate('/View-Startup', { state: item?.sectorSno })}>
                                        View More
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>) : (<>
                        <div className="m-5 text-center ">
                            <img src="/assets/9264828.jpg" alt="" width={'400'} />
                            <div className='h3 text-secondary'> Startup Not Found!</div>
                        </div>
                    </>)}
                </div>
            </div>
            <ViewStartupDialoge open={open} handleClose={handleClose} selectedItem={selectedItem} />

        </>
    );
}