import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StartupContent from '../view-sector/StartupContent';
import TabTwo from './TabTwo';
import TabThree from './Investors';
import StartUpTap from './StartUpTap';
import ApiService from '../../common/ApiService';
import MainDetail from '../view-sector/MainDetail';
import Investors from './Investors';
import GovScheme from './GovScheme';
import Incubators from './Incubators';
import Mentors from './Mentors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tab: {
    minWidth: 'auto', // Set tabs to minimum width
    padding: theme.spacing(1, 2), // Add padding to tabs
    textTransform: 'none', // Prevent text transformation
  },
  indicator: {
    backgroundColor: 'transparent', // Hide default indicator
  },
  selectedTab: {
    backgroundColor: '#4a007e', // Change background color of selected tab
    color: 'white',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#4a007e', // Change background color on hover
    },
  },
}));

function AdvancedTabBar(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const apiService = new ApiService();
  const [contentList, setContentList] = useState(null);

  useEffect(() => {
    // Perform data fetching or any other side effect here
    fetchTapContent(0);

  }, [props]); // Empty dependency array means this effect runs only once
  async function fetchTapContent(value) {
    setContentList(null)
    var body = { 'sectorSno': props?.sectorSno, 'sectorDtlTabSno': value  };
    console.log(body)
    try {
      const response = await apiService.post('search_sector_dtl_content_tabs', body);
      if (response && response?.data) {
        setContentList(response?.data)
        console.log(contentList)
      }else{
        setContentList([])

      }
      //   setData(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    fetchTapContent(newValue)
  };

  return (
    <Paper square elevation={0} className='mt-5'>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable" // Allow scrolling for many tabs
        scrollButtons="auto" // Show scroll buttons when tabs overflow
        classes={{ indicator: classes.indicator }} // Customize indicator style
      >
        <Tab label="Home" className={`${classes.tab} ${value === 0 ? classes.selectedTab : ''}`} />
        <Tab label="Introduction" className={`${classes.tab} ${value === 1 ? classes.selectedTab : ''}`} />
        <Tab label="Initiatives" className={`${classes.tab} ${value === 2 ? classes.selectedTab : ''}`} />
        <Tab label="Resources" className={`${classes.tab} ${value === 3 ? classes.selectedTab : ''}`} />
        <Tab label="Startup" className={`${classes.tab} ${value === 4 ? classes.selectedTab : ''}`} />
        <Tab label="Gov Scheme" className={`${classes.tab} ${value === 5 ? classes.selectedTab : ''}`} />
        <Tab label="Investors" className={`${classes.tab} ${value === 6 ? classes.selectedTab : ''}`} />
        <Tab label="Incubators" className={`${classes.tab} ${value === 7 ? classes.selectedTab : ''}`} />
        <Tab label="Mentors" className={`${classes.tab} ${value === 8 ? classes.selectedTab : ''}`} />
        {/* Add more tabs as needed */}
      </Tabs>

      {/* Render child components based on the selected tab */}
      {value === 0 && <MainDetail sectorSno={props?.sectorSno} />}
      {value === 1 && <StartupContent contentList={contentList} />}
      {value === 2 && <StartupContent contentList={contentList} />}
      {value === 3 && <StartupContent contentList={contentList} />}
      {value === 4 && <StartUpTap sectorSno={props?.sectorSno} />}
      {value === 5 && <GovScheme sectorSno={props?.sectorSno} />}
      {value === 6 && <Investors sectorSno={props?.sectorSno} />}
      {value === 7 && <Incubators sectorSno={props?.sectorSno}/>}
      {value === 8 && <Mentors sectorSno={props?.sectorSno}/>}
    </Paper>
  );
}

export default AdvancedTabBar;
