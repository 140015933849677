import React, { useState } from 'react';
import {
    FaBars,
    FaHistory
} from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import { MdDevices, MdSpaceDashboard } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { PiCertificateBold } from "react-icons/pi";
import logoImg from '../assets/Startuptn-logo.png'; // Import your logo image


const Sidebar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);
    const menuItem = [
        {
            path: "/",
            name: "Home",
            icon: <FaHome />
        },
        {
            path: "/sector",
            name: "Secotor",
            icon: <MdDevices />
        },
        {
            path: "/history",
            name: "Categary",
            icon: <PiCertificateBold />
        }

    ]
    return (
        <>
            {/* <div class='header rounded d-flex justify-content-end p-2'>
                <div class='w-25 float-end'>
                    <img src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png" class="rounded-circle" alt="Cinque Terre" width="40" height="40" />
                </div>
            </div> */}
            <div class="container1" style={{}}>

                <div style={{ width: isOpen ? "260px" : "80px",zIndex:'1' }} class="sidebar">
                    <div class="top_section">
                        <h1 style={{ display: isOpen ? "block" : "none" }} class="logo">
                        <img src={logoImg} alt="Logo" width={'150px'} />

                        </h1>
                        <div style={{ marginLeft: isOpen ? "50px" : "0px" }} class="bars">
                            <FaBars onClick={toggle} />
                        </div>
                    </div>
                    {
                        menuItem.map((item, index) => (
                            <NavLink to={item.path} key={index} class="link" activeclass="active">
                                <div class="icon">{item.icon}</div>
                                <div style={{ display: isOpen ? "block" : "none" }} class="link_text">{item.name}</div>
                            </NavLink>
                        ))
                    }
                </div>
                <main>

                    {children}</main>
            </div>
        </>

    );
};

export default Sidebar;