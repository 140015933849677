import { useEffect, useState } from "react";
import ApiService from "../../common/ApiService";
import bglogo from '../../assets/user-card/stn-avtar.png'
import { BsFillPersonFill } from "react-icons/bs";

export default function ProfileCard(props) {
    const apiService = new ApiService();
    const [profile, setProfile] = useState(null);

    // const profile = props.profileData;
    useEffect(() => {
        // Perform data fetching or any other side effect here
        fetchSector();
    }, [props]); // Empty dependency array means this effect runs only once
    async function fetchSector() {
        var body = { sectorSno: props?.sectorSno };
        try {
            const response = await apiService.post('get_sector', body);
            // console.log(response)
            if (response && response?.data) {
                setProfile(response?.data[0])
            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <>
            <div className="card shadow col-12 col-sm-6 col-md-4  round-radius p-2" style={{ borderRadius: '20px' }}>
                <   div className="text-center ">
                    <img src={profile?.bannerImageUrl} onError={(e) => {
                        e.target.src = bglogo;
                        e.target.onerror = null;
                    }} className="rounded-circle" width={100} height={100} alt="Profile Image" />
                    {/* <div className='round-div'>
                        <FiEdit2 />
                    </div> */}
                    {/* <h6 className='text-secondary text-center py-2'><BsFillPersonFill /> {profile?.inchargeName ?? 'Incharge'}</h6> */}
                    <h3 className='text-center pt-2'>{profile?.sectorName ?? 'Incharge'} Sector</h3>

                    <div className="card-body text-center">

                        <div className="row">
                            <div className="col-6 border-top border-right p-2">
                                <p className='text-secondary h6'>Districts</p>
                                <div>
                                    <b className="h5">16 +</b>
                                </div>
                            </div>

                            <div className="col-6 p-2" style={{borderTop:'1px solid lightgrey',borderLeft:'1px solid lightgrey'}}>
                                <p className='text-secondary h6'>STARTUPS</p>
                                <div>
                                    <b className="h5">{profile?.startupCount - 1} +</b>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}