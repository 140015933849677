import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../common/common.css';
const Breadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();

  useEffect(() => {
    // Parse the current pathname to generate breadcrumbs
    const pathnames = location.pathname.split('/').filter((x) => x);

    const breadcrumbs = pathnames.map((_, index) => {
      const url = `/${pathnames.slice(0, index + 1).join('/')}`;
      return (
        <li key={url}>
          <Link to={url}>{pathnames[index]}</Link>
        </li>
      );
    });

    setBreadcrumbs(breadcrumbs);
  }, [location]);

  return (
      <nav aria-label="breadcrumb" className='px-5'>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        {breadcrumbs}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
