import { useLocation } from "react-router-dom";
import ProfileCard from "../sector/profile-card";
import { useEffect, useState } from "react";
import ApiService from "../../common/ApiService";
import { debounce } from "@material-ui/core";
import { IoSearch } from "react-icons/io5";
import { TextField } from "@mui/material";
import ViewStartupDialoge from "../../common/View-StartUp-Dialoge";
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function ViewStartup() {
    const apiService = new ApiService();
    const location = useLocation();
    const [sectorSno, setSectorSno] = useState(location.state);
    const [startup, setStartup] = useState({});
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const isMobile = useMediaQuery('(max-width: 767px)');

    const handleClickOpen = (item) => {
        console.log(item)
        setSelectedItem(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSearchChange = (event) => {
        console.log(event.target.value)
        debounceSearchApiCall(event.target.value)
    };
    const debounceSearchApiCall = debounce((searchQuery) => {
        fetchData(searchQuery)
        console.log('Making API call with search query:', searchQuery);
    }, 300);
    useEffect(() => {

        fetchData('');
    }, []);
    const fetchData = async (searchKey) => {
        var body = { "sectorSno": sectorSno, 'skip': 0, 'limit': 10, 'entityName': searchKey };
        try {
            const response = await apiService.post('get_startup_by_sector', body);
            console.log(response)
            if (response && response?.data) {
                setStartup(response?.data[0])
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <>
            <div className={!isMobile ? "px-5 mx-3" : 'px-1 m-auto text-center'}>
                <div className="row header-div">
                    <div className=" p-4 round-radius header-div" style={{
                        backgroundImage: `url(${startup?.bannerImage})`,
                        backgroundSize: 'cover',
                        borderRadius: '2pc'
                    }}>
                        <ProfileCard sectorSno={startup?.sectorSno} />
                    </div>
                </div>
                <br />
                <TextField className="px-2"
                    fullWidth
                    id="standard-basic"
                    placeholder="Search By Startup..."
                    value={searchValue}
                    onChange={handleSearchChange}
                    sx={{
                        mt: 1,
                        '& fieldset': {
                            borderColor: '#f2f3f5', // Change border color to light grey
                        },
                        '&:hover fieldset': {
                            borderColor: '#f2f3f5', // Add hover effect
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#f2f3f5', // Add focus effect
                        },
                        pb: 2, // Add padding bottom
                    }}
                    InputProps={{
                        startAdornment: (
                            <IoSearch style={{ marginRight: '8px', color: '#757575' }} />
                        ),
                    }}
                />
                <div className="col-12">

                    <div className="ag-courses_box mt-4 p-0" style={{ paddingRight: '0px', paddingLeft: '0px', flexWrap: 'wrap' }}>
                        {startup?.startupList?.map((start) => (
                            <div class="ag-courses_item " key={start?.entitySno} onClick={() => handleClickOpen(start)}>
                                <a class="ag-courses-item_link">
                                    <div class="ag-courses-item_bg"></div>

                                    <div class="ag-courses-item_title">
                                        {start?.entityName}
                                    </div>

                                    <div class="ag-courses-item_date-box">
                                        District:
                                        <span class="ag-courses-item_date">
                                            {start?.districtName}
                                        </span>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <ViewStartupDialoge open={open} handleClose={handleClose} selectedItem={selectedItem} />

        </>
    );
}