import { useEffect, useState } from "react";
import ApiService from "../../common/ApiService";
import RotateLoader from "react-spinners/RotateLoader";
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function GovScheme({ sectorSno }) {
    const apiService = new ApiService();
    const isMobile = useMediaQuery('(max-width: 767px)');

    const [schemeList, setSchemeList] = useState(null);
    console.log(sectorSno)
    useEffect(() => {
        get_gov_scheme();
    }, [sectorSno])

    async function get_gov_scheme() {
        setSchemeList(null)
        var body = { 'sectorSno': sectorSno, 'skip': 0, "limit": 10 };
        console.log(body)
        try {
            const response = await apiService.post('get_gov_scheme', body);
            console.log(response)
            if (response && response?.data) {
                setSchemeList(response?.data)
            } else {
                setSchemeList([])

            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <>
            {(schemeList != null && schemeList?.length > 0) ? (<div className="container py-3 row ">
                {schemeList.map((item, index) => (
                    <div className=" col-md-6 col-sm-12 p-1" key={index}>
                        <div className="card p-3">
                        {isMobile&&( <div className="text-center w-100 ">
                                    <img className="rounded"
                                     src={item?.scheme_img?.image_url?item?.scheme_img?.image_url:'/assets/images.png'}
                                     onError={(e) => {
                                        e.target.onerror = null; // Prevents infinite loop if fallback also fails
                                        e.target.src = '/assets/images.png'; // Fallback image path
                                      }} alt="" width={'100px'}/>
                                </div>)}
                            <div className="d-flex justify-content-between ">
                                <div className="col-md-8 ">
                                    <div className="card-block px-6">
                                        <h4 className="card-title">{item?.scheme_name}</h4>
                                        <p className="card-text text-secondary">
                                            {item?.scheme_desc}
                                        </p>
                                        <br />
                                    </div>
                                </div>
                               {!isMobile&&( <div className="float-end me-0 ">
                                    <img className="d-block rounded" src={item?.scheme_img?.image_url?item?.scheme_img?.image_url:'/assets/images.png'}
                                     onError={(e) => {
                                        e.target.onerror = null; // Prevents infinite loop if fallback also fails
                                        e.target.src = '/assets/images.png'; // Fallback image path
                                      }} alt="" width={'180'} />
                                </div>)}
                            </div>
                        </div>
                    </div>
                ))}

            </div>) : (schemeList && schemeList.length == 0) ? (<div className="text-center my-4">
                <img src="/assets/no data/images.png" alt="" width={'300px'} />
                <div className="h6 text-secondary">Schemes Not Found !</div>
            </div>) : (<div className="my-5 text-center ">
                <br></br>
                <RotateLoader color="#212529" />
            </div>)}



        </>
    );
}