import { useEffect, useState } from "react";
import ApiService from "../../common/ApiService";
import { IoCheckmarkCircle } from "react-icons/io5";

export default function MainDetail(props) {
    const apiService = new ApiService();
    const [contentList, setContentList] = useState(null);

    useEffect(() => {
        // Perform data fetching or any other side effect here
        fetchMainContent();
    }, [props]); // Empty dependency array means this effect runs only once
    async function fetchMainContent() {
        var body = {'sectorSno':props?.sectorSno};
        try {
            const response = await apiService.post('search_sector_main', body);
            if (response && response?.data) {
                setContentList(response?.data)
                console.log(contentList)
            }else{
                setContentList(null)

            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <>
            <div className="card p-3 mt-5" >
                <div> <b>STARTUPS</b></div>
                <hr></hr>
                {contentList?.map((item, index) => (
                    <div className="content-style px-2 mb-2" key={index}>
                        <IoCheckmarkCircle  style={{color:'#4a007e'}}/>
                        {item?.title != null ? (<span className="h6 text-dark"><b>{item.title} </b></span>) : (null)}
                        {item?.content}                                    <br></br>
                    </div>
                ))}

            </div>

        </>
    );
}