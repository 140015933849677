import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard.jsx';
import History from './pages/History.jsx';
import Sectors from './pages/sector/Sectors.jsx';
import HorizondalHeader from './components/horizondal-header.jsx';
import ViewSector from './pages/view-sector/ViewSector.jsx';
import StartupHome from './pages/StartUp/StartupHome.jsx';
import Breadcrumbs from './common/Breadcrumbs.jsx';
import ViewStartup from './pages/view-startup/ViewStartup.jsx';

const App = () => {
  return (
    <>
      <HorizondalHeader />
      <br /><br />
      <Breadcrumbs />
      <br />
      <Routes>
        <Route path="/" element={<Sectors />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/sector" element={<Sectors />} />
        <Route path="/history" element={<History />} />
        <Route path="/View-Sector" element={<ViewSector />} />
        <Route path="/Startup" element={<StartupHome />} />
        <Route path="/View-Startup" element={<ViewStartup />} />
      </Routes>
    </>
  );
};

export default App;
