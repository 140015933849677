import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import ProfileCard from "../sector/profile-card";
import StartUpTap from "../taps/StartUpTap";
import AdvancedTabBar from "../taps/TabBar";
import { useLocation } from "react-router-dom";
import AdvancedSearchBox from "./AdvancedSearchBox";
import AdvancedSearchDistrict from "./AdvancedSearchDistrict";
import bglogo from '../../assets/user-card/stn-avtar.png'
import { MdEmail } from "react-icons/md";
import { AiTwotoneMobile } from "react-icons/ai";
import ApiService from "../../common/ApiService";
import SectorSearchBox from './SectorSearch';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function ViewSector() {
    const apiService = new ApiService();
    const location = useLocation();
    const [sectorList, setSectorList] = useState();
    const [sectorData, setSectorData] = useState(location.state);
    const isMobile = useMediaQuery('(max-width: 767px)');

    const [params, setParams] = useState({
        "sectorSno": location?.state?.sectorSno,
        "sectorName": location?.state?.sectorName,
        "bannerImageUrl": location?.state?.bannerImageUrl,
        "inchargeImageUrl": location?.state?.inchargeImageUrl,
        "inchargeMailId": location?.state?.inchargeMailId,
        "inchargeMobileNo": location?.state?.inchargeMobileNo,
        "inchargeName": location?.state?.inchargeName,
    });
    console.log(location.state)
    useEffect(() => {
        console.log(params)
        fetchSector();

    }, [params]);
    async function fetchSector() {
        console.log(params)
        var body = {};
        console.log(body)
        try {
            const response = await apiService.post('get_sector', body);
            if (response && response?.data) {
                setSectorList(response?.data)
                console.log(sectorList)
            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const sectorFunction = async (item) => {
        setSectorData(item);

        setParams(prevParams => ({
            ...prevParams,  // Keep existing values
            ...item    // Update with new values
        }));
        console.log(params)
    };

    return (
        <div className={!isMobile ? "px-5 mx-3" : 'px-1 m-auto text-center'}>
            <div className="row header-div">
                <div className=" p-4 round-radius header-div" style={{
                    backgroundImage: `url(${sectorData?.bannerImageUrl ?? sectorData?.bannerImage})`,
                    backgroundSize: 'cover',
                    borderRadius: '2pc'
                }}>
                    <ProfileCard sectorSno={sectorData?.sectorSno} />
                </div>
            </div>
            <br />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SectorSearchBox sectorList={sectorList} sectorFunction={sectorFunction} sectorData={sectorData} />
                    {/* <AdvancedSearchDistrict /> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={9}>
                    <Grid container spacing={2} className="">
                        <Grid item xs={12}>
                            <div className="card p-3 shadow-sm" style={{ border: '1px solid #f4f5f7' }} >
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={6}>
                                        <div className="d-flex">
                                            <div>
                                                <img src={sectorData?.inchargeImageUrl} onError={(e) => {
                                                    e.target.src = bglogo;
                                                    e.target.onerror = null;
                                                }} className="rounded-circle" width={100} height={100} alt="Profile Image" />
                                            </div>
                                            <div>
                                                <h4 className='text-start py-1 px-2'>{sectorData?.sectorName ?? 'NA'} Sector</h4>
                                                <h5 className='text-dark text-start px-2'> {sectorData?.inchargeName ?? 'Incharge'}</h5>
                                                <h5 className='text-secondary text-start  px-2'>INCHARGE</h5>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div>
                                            <h6 className='text-secondary text-start py-1 px-2'> <MdEmail /> {sectorData?.inchargeMailId ?? 'NA'}</h6>
                                            <h6 className='text-secondary text-start py-1 px-2'> <AiTwotoneMobile /> {sectorData?.inchargeMobileNo ?? 'NA'}</h6>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <StartUpTap sectorSno={sectorData?.sectorSno} />
                    <AdvancedTabBar sectorSno={sectorData?.sectorSno} />
                </Grid>
            </Grid>
        </div>
    );
}
