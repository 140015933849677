// import '../../pages/view-sector/view-sector.css';
import { useEffect, useState } from 'react';
import ApiService from '../../common/ApiService';
import '../../pages/taps/investor.css'
import { RotateLoader } from 'react-spinners';

export default function Investors({ sectorSno }) {
    const apiService = new ApiService();
    const [investorsList, setInvestorsList] = useState(null);
    console.log(sectorSno)
    useEffect(() => {
        getInvestors();
    }, [sectorSno])

    async function getInvestors() {
        setInvestorsList(null)
        var body = { 'sectorSno': sectorSno, 'skip': 0, "limit": 10 };
        console.log(body)
        try {
            const response = await apiService.post('get_investors', body);
            console.log(response)
            if (response && response?.data) {
                setInvestorsList(response?.data)
            } else {
                setInvestorsList([])
            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    return (
        <>
            <br></br>
            {(investorsList != null && investorsList?.length > 0) ?(<div class="container">
                <div class="row text-center">

                    {investorsList?.map((item, index) => (
                        <div class="col-xl-3 col-sm-6 mb-5" key={index}>
                            <div class="bg-white rounded shadow py-5 px-4"><img src={item?.investor_profile?.img?item?.investor_profile?.img:'/assets/images.png'} 
                             onError={(e) => {
                                e.target.onerror = null; // Prevents infinite loop if fallback also fails
                                e.target.src = '/assets/images.png'; // Fallback image path
                              }} alt="" width="100" height='100' class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm" />
                                <h5 class="mb-0">{item?.investor_name}</h5><span class="h6 text-uppercase" style={{color:item?.investor_type=='Angel'?'green':'red'}}>{item?.investor_type}</span>
                                <ul class="social mb-0 list-inline mt-3">
                                    <li class="list-inline-item"><a href="#" class="social-link" style={{fontSize:'16px'}}>{item?.investor_email}</a></li>
                                </ul>
                                <ul class="social mb-0 list-inline">
                                    <li class="list-inline-item"><a href="#" class="social-link" style={{fontSize:'16px'}}>{item?.mobile}</a></li>
                                </ul>
                            </div>
                        </div>

                    ))}

                </div>
            </div>):(investorsList && investorsList.length == 0) ? (<div className="text-center my-4">
                <img src="/assets/no data/images.png" alt="" width={'300px'} />
                <div className="h6 text-secondary">Investors Not Found !</div>
            </div>) : (<div className="my-5 text-center ">
                <br></br>
                <RotateLoader color="#212529" />
            </div>)}
        </>
    );
}