// import { Stack } from "@mui/material";
// import { MdCall } from "react-icons/md";
// import { MdAccessTimeFilled } from "react-icons/md";
// import { MdMarkEmailUnread } from "react-icons/md";

// export default function HorizondalHeader() {
//     return (
//         <>
//             <Stack className=" p-2" style={{ position: 'absolute', width: '100%', background: '#38157c' }}>

//                 <div className="container d-flex justify-content-between">
//                     <div className=" d-flex">
//                         <div className="text-white"><MdCall /> 155343</div>
//                         <div className="text-white px-2"><MdAccessTimeFilled className="mx-1" /> Monday - Friday : 10:00 AM - 06:00 PM</div>
//                         <div className="text-white px-2"><MdMarkEmailUnread className="mx-1" /> <b>support@startuptn.in</b></div>
//                     </div>
//                     <div className=" d-flex">
//                         <div className="text-white px-3">Login</div>
//                         <div className="text-white px-3">Register</div>
//                         <div className="text-white px-3">English</div>
//                         <div className="text-white px-3"><b>தமிழ்</b></div>
//                     </div>
//                 </div>

//             </Stack>
//         </>
//     )
// }


import { Stack } from "@mui/material";
import { MdCall } from "react-icons/md";
import { MdAccessTimeFilled } from "react-icons/md";
import { MdMarkEmailUnread } from "react-icons/md";
import { useState } from "react";
import { IoClose } from "react-icons/io5";

export default function HorizontalHeader() {
    // State to manage sidebar toggle
    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <>
            <Stack className="p-2" style={{ position: 'absolute', width: '100%', background: '#38157c' }}>
                <div className="container d-flex justify-content-between">
                    <div className="horizontal-menu d-none d-md-flex text-white">
                        <div className="text-white"><MdCall /> 155343</div>
                        <div className="text-white px-2"><MdAccessTimeFilled className="mx-1" /> Monday - Friday : 10:00 AM - 06:00 PM</div>
                        <div className="text-white px-2"><MdMarkEmailUnread className="mx-1" /> <b>support@startuptn.in</b></div>
                    </div>
                    {/* <div className="horizontal-menu d-none d-md-flex text-white">
                        <div className="text-white px-3">Login</div>
                        <div className="text-white px-3">Register</div>
                        <div className="text-white px-3">English</div>
                        <div className="text-white px-3"><b>தமிழ்</b></div>
                    </div> */}
                    <div className="d-md-none text-white sidebar-toggle" onClick={() => setSidebarOpen(!sidebarOpen)}>
                        ☰
                    </div>
                </div>
            </Stack>
            <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
                <div className="sidebar-content text-white">
                    <div className="text-white float-end h5" onClick={() => setSidebarOpen(!sidebarOpen)}><IoClose /></div>
                    <div className="text-white"><MdCall /> 155343</div>
                    <div className="text-white px-2 py-1"><MdAccessTimeFilled className="mx-1" /> Monday - Friday : 10:00 AM - 06:00 PM</div>
                    <div className="text-white px-2 py-1"><MdMarkEmailUnread className="mx-1" /> <b>support@startuptn.in</b></div>
                    {/* <div className="text-white px-3 py-1">Login</div>
                    <div className="text-white px-3 py-1">Register</div>
                    <div className="text-white px-3 py-1">English</div>
                    <div className="text-white px-3 py-1"><b>தமிழ்</b></div> */}
                </div>
            </div>
        </>
    );
}
