import { MdEmail, MdLanguage } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import ApiService from "../../common/ApiService";
import { Card, CardContent, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { CiMobile1 } from "react-icons/ci";
import { HiOutlineMail } from "react-icons/hi";
import bglogo from '../../assets/user-card/logo.png'
import AdvancedSearchStartup from "../view-sector/AdvancedSearchStartup";
import { IoSearch } from "react-icons/io5";

export default function StartUpTap(props) {
    const apiService = new ApiService();
    const [entityList, setEntityList] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [searchValue, setSearchValue] = useState();

    const handleClickOpen = (item) => {
        console.log(item)
        setSelectedItem(item);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        // Perform data fetching or any other side effect here
        fetchEntity();
    }, [props]); // Empty dependency array means this effect runs only once
    async function fetchEntity() {
        var body = {  "sectorSno": props?.sectorSno ,skip: 0, limit: 10,};
        console.log(body)
        try {
            const response = await apiService.post('get_entity', body);
            console.log(response)

            if (response && response?.data) {
                setEntityList(response?.data)
            }else{
                setEntityList([])
            }
            //   setData(jsonData); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const handleSearchChange = (event) => {
        console.log(event.target.value)
        if (event.target.value?.length > 0) {
            const filtered = entityList.filter(item =>
                item.entityName.toLowerCase().includes(event.target.value.toLowerCase())
            );
            setEntityList(filtered)
        } else {
            fetchEntity()
        }
    };

    return (
        <>
            <TextField
                fullWidth
                id="standard-basic"
                placeholder="Search By Startup..."
                value={searchValue}
                onChange={handleSearchChange}
                sx={{
                    mt: 1,
                    '& fieldset': {
                        borderColor: '#f2f3f5', // Change border color to light grey
                    },
                    '&:hover fieldset': {
                        borderColor: '#f2f3f5', // Add hover effect
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#f2f3f5', // Add focus effect
                    },
                    pb: 2, // Add padding bottom
                }}
                InputProps={{
                    startAdornment: (
                        <IoSearch style={{ marginRight: '8px', color: '#757575' }} />
                    ),
                }}
            />
            {/* <AdvancedSearchStartup /> */}

            <Grid container spacing={3} marginTop={0}>
                {entityList?.map((item, index) => (
                    <Grid item xs={12} md={6} key={index} onClick={() => handleClickOpen(item)}>
                        <Card className="p-2 shadow-sm rounded" style={{border:'1px solid #f4f5f7'}} sx={{ minHeight: '100px'}} elevation={0}>
                            <CardContent className="d-flex">
                                <img className="rounded-circle" src={item?.logoPath}
                                    onError={(e) => {
                                        e.target.src = bglogo; // Replace with fallback image when error occurs
                                        e.target.onerror = null; // Prevent infinite loop in case fallback image also fails
                                    }}
                                    alt="" width={60} height={60} />
                                <div className="px-2">
                                    <Typography variant="subtitle1">{item?.entityName}</Typography>
                                    <Typography variant="body2" color="primary">{item?.primaryWebsite}</Typography>
                                    <Typography variant="body2" color="textSecondary">{item?.districtName ?? 'NA'}</Typography>

                                </div>
                            </CardContent>
                            {/* <Typography variant="subtitle1" className="text-secondary"><MdEmail /> {item?.primaryMailId?.length>1?item?.primaryMailId:'****'}</Typography>
                            <Typography variant="subtitle1" className="text-secondary"><FaMobileAlt /> {item?.primaryPhoneNo?.length>1?item?.primaryPhoneNo : '****'}</Typography> */}
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">
                    {<>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex py-2" >
                                <div >
                                    <img className="rounded-circle" src={selectedItem?.logoPath} width={60} height={60} alt="" />
                                </div>
                                <div>
                                    <p className="px-4 h5"><b>{selectedItem?.entityName}</b></p>
                                    <p className="px-4 text-secondary">{selectedItem?.districtName}</p>
                                </div>

                            </div>
                            <div>
                                <p className="px-4 text-secondary">Sector</p>
                                <p className="px-4 h6"><b>{selectedItem?.sectorName}</b></p>

                            </div>
                        </div>

                        <p ><a href={selectedItem?.primaryWebsite}><MdLanguage /> {selectedItem?.primaryWebsite}</a></p>

                    </>

                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className="p-1 " style={{ fontSize: '18px' }}>
                        {selectedItem?.entityUsp}
                        <div className="d-flex justify-content-between">
                            <div className="mt-2" >
                                MOBILE
                                <div className="h5 text-dark"><CiMobile1 />{selectedItem?.primaryPhoneNo?.length > 1 ? selectedItem?.primaryPhoneNo : 'NA'}</div>
                            </div>
                            <div className="mt-2">
                                EMAIL
                                <div className="h5 text-dark"><HiOutlineMail />{selectedItem?.primaryMailId?.length > 1 ? selectedItem?.primaryMailId : 'NA'}</div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>

                {/* <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    );
}