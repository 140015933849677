import '../../pages/view-sector/view-sector.css';
import { IoCheckmarkCircle } from "react-icons/io5";


export default function StartupContent(props) {
    const contentList = props?.contentList;
    // console.log( typeof contentList[1].content)
    const parseContent = (content) => {
        if (typeof content === 'string') {
            try {
                const parsedContent = JSON.parse(content);
                if (Array.isArray(parsedContent)) {
                    return parsedContent;
                }
            } catch (error) {
                // Error while parsing JSON, treat content as plain text
            }
        }
        return [content]; // Return as single-element array if not valid JSON array
    };

    return (
        <>
            {contentList?.map((item, index) => (
                <div className="content-style p-3 mt-0" key={index} >
                    {item?.title && <div className="h4"><b>{item.title}</b></div>}
                    <div className="content-style px-0 mb-0">
                        {parseContent(item?.content)?.map((sentence, idx) => (
                            <p  key={idx}>
                    <IoCheckmarkCircle  style={{color:'#4a007e'}}/>
                                {sentence}
                                </p>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );
}