import { Collapse } from "@mui/material";
import { CiLocationOn } from "react-icons/ci";
import ApiService from "../../common/ApiService";
import { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { FaLocationArrow } from "react-icons/fa";



const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(0),
        },

    },
}));

export default function AdvancedSearchStartup() {
    const apiService = new ApiService();
    const [districtList, setDistrictList] = useState(null);
    const [expandedDistrict, setExpandedDistrict] = useState(false);
    const classes = useStyles();

    const handleExpandDistrict = () => {
        setExpandedDistrict(!expandedDistrict);
    };

    useEffect(() => {
        fetchDistrict();
    }, []);
    async function fetchDistrict() {
        var body = {};
        try {
            const response = await apiService.post('search_district', body);
            if (response && response?.data) {
                setDistrictList(response?.data)
            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <>
            <div className="card p-3 mt-5" >
                <div onClick={handleExpandDistrict}> <b>Search By Startup </b></div>
                {expandedDistrict ? (<hr></hr>) : (null)}
                <div className={classes.root}>
                    {/* <Button className='text-start' variant="contained" color="primary" onClick={handleExpandClick} fullWidth>
                        Sectors
                    </Button> */}
                    <Collapse in={expandedDistrict}>
                        {districtList?.map((item, index) => (
                            <div className="d-flex justify-content-between " key={index} >
                                <div className="d-flex">
                                    <CiLocationOn />
                                    <p className='px-3'>{item?.districtName}</p>
                                </div>
                                <FaLocationArrow className='pt-1' />
                            </div>
                        ))}


                    </Collapse>
                </div>
            </div>
        </>
    );
}