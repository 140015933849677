import '../../pages/sector/sector-header.css'
import logo from '../../assets/11.png'
import bglogo from '../../assets/user-card/stn-avtar.png'
import { FaFacebookF } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { FaArrowUpWideShort } from "react-icons/fa6";
import { BsFillPersonFill } from "react-icons/bs";

import { IoLogoGoogleplus } from "react-icons/io";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaCaretUp } from "react-icons/fa";

import { FaInstagram } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
export default function SectorCard(props) {
    const sectorList = props?.sectorData;
    const navigate = useNavigate();
    console.log(sectorList)
    return (
        <>

            <br></br>
            <br></br>
            <div className="row mt-2" >
                {sectorList?.map((item, index) => (
                    <div className="col-sm-6 col-lg-6 col-xl-3 box-col-33" key={item?.sectorSno} onClick={() => navigate("/View-Sector", { state: item })}>
                        <div className="card custom-card shadow" style={{ minHeight: '460px', maxHeight: '560px' }}>
                            <div className="card-header"><img style={{ height: '120px' }} className="img-fluid" src={item?.bannerImageUrl} alt="" /></div>
                            <div className="card-profile"><img className="rounded-circle" src={item?.inchargeImageUrl?.length > 1 ? item?.inchargeImageUrl : bglogo}
                                onError={(e) => {
                                    e.target.src = bglogo; // Replace with fallback image when error occurs
                                    e.target.onerror = null; // Prevent infinite loop in case fallback image also fails
                                }}
                                alt="" width={140} height={80} /></div>
                            <h6 className='text-secondary text-center py-1'><BsFillPersonFill /> {item?.inchargeName ?? 'Incharge'}</h6>
                            <h5 className='text-center py-2'>{item?.sectorName ?? 'Incharge'} Sector</h5>

                            
                            <div className="card-footer row">
                                <div className="col-6 col-sm-6">
                                    <h6 className=''><b>STARTUPS</b></h6>
                                    <h3 className="counter textprimary">{item?.startupCount - 1} +</h3>
                                </div>
                                {/* <div className="col-4 col-sm-4">
                                    <h6>GROWTH</h6>
                                    <h3><span className="counter textprimary">25</span>% <FaCaretUp className='text-success' /></h3>
                                </div> */}
                                <div className="col-6 col-sm-6" onClick={() => navigate("/View-Sector", { state: item })}>
                                    <h6>View More</h6>
                                    <h5><span className="counter text-primary">Details</span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* <div className="row mt-2" >
                {sectorList?.map((item, index) => (
                    <div className="col-sm-6 col-lg-6 col-xl-3 box-col-33" key={item?.sectorSno} onClick={() => navigate("/View-Sector", { state: item })}>
                        <div className="card custom-card" style={{ minHeight: '460px', maxHeight: '560px' }}>
                            <div className="card-header"><img style={{ height: '120px' }} className="img-fluid" src={item?.bannerImageUrl} alt="" /></div>
                            <div className="card-profile"><img className="rounded-circle" src='/assets/images.png'
                                onError={(e) => {
                                    e.target.src = bglogo; // Replace with fallback image when error occurs
                                    e.target.onerror = null; // Prevent infinite loop in case fallback image also fails
                                }}
                                alt="" width={120} height={100} /></div>
                            <h6 className='text-secondary text-center py-1'><BsFillPersonFill /> {item?.inchargeName ?? 'Incharge'}</h6>
                            <h5 className='text-center py-2'>{item?.sectorName ?? 'Incharge'} Sector</h5>

                           
                            <div className="card-footer row">
                                <div className="col-6 col-sm-6">
                                    <h6 className=''><b>STARTUPS</b></h6>
                                    <h3 className="counter textprimary">{item?.startupCount - 1} +</h3>
                                </div>
                                
                                <div className="col-6 col-sm-6" onClick={() => navigate("/View-Sector", { state: item })}>
                                    <h6>View More</h6>
                                    <h5><span className="counter text-primary">Details</span></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div> */}
        </>
    )
}