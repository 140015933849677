import { Autocomplete, Collapse, TextField } from "@mui/material";
import { CiLocationOn } from "react-icons/ci";
import ApiService from "../../common/ApiService";
import { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { FaLocationArrow } from "react-icons/fa";
import useMediaQuery from '@material-ui/core/useMediaQuery';



const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(0),
        },

    },
}));

export default function AdvancedSearchDistrict(props) {
    const apiService = new ApiService();
    const [expandedDistrict, setExpandedDistrict] = useState(true);
    const [selectedValue, setSelectedValue] = useState(null);

    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width: 767px)');

    console.log(props)
    const handleExpandDistrict = () => {
        setExpandedDistrict(!expandedDistrict);
    };
    const handleAutocompleteChange = (event, newValue) => {
        setSelectedValue(newValue);
        console.log(newValue)
        props.districtFunction(newValue);
        // fetchDistrict(newValue)

    };



    return (
        <>
            <div className="card p-3 mx-2" >
                <div onClick={handleExpandDistrict}> <b>Districts </b></div>
                {expandedDistrict ? (<hr></hr>) : (null)}
                <Autocomplete
                    onChange={handleAutocompleteChange}
                    options={props?.districtList}
                    getOptionLabel={(option) => option?.districtName} // Specify which property to use as the label
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search By District ..."
                            sx={{
                                '& fieldset': {
                                    borderColor: '#f2f3f5', // Change border color to light grey
                                },
                                '&:hover fieldset': {
                                    borderColor: '#f2f3f5', // Add hover effect
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#f2f3f5', // Add focus effect
                                },
                                pb: 2, // Add padding bottom
                            }}
                        />
                    )}
                />
                <div className={classes.root}>
                    {/* <Button className='text-start' variant="contained" color="primary" onClick={handleExpandClick} fullWidth>
                        Sectors
                    </Button> */}
                    {!isMobile && (

                        <Collapse in={expandedDistrict}>
                            {props?.districtList?.map((item, index) => (
                                <div className="d-flex justify-content-between py-2 px-2" key={index} onClick={() => props?.districtFunction(item)}
                                    style={{ background: item?.filtered ? '#f8e7b9' : 'transparent', borderRadius: '5px' }}
                                >
                                    <div className="d-flex">
                                        <CiLocationOn />
                                        <div className='px-3 '>{item?.districtName}</div>
                                    </div>
                                    <FaLocationArrow className='pt-1' />
                                </div>
                            ))}


                        </Collapse>
                    )}
                </div>
            </div>
        </>
    );
}