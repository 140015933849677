import React, { useEffect, useState } from 'react';
import '../../pages/sector/sector.css'
import SectorCard from './Sector-card';
import { FaSearch } from "react-icons/fa";
import ApiService from '../../common/ApiService';
import DashBoardCard from './DashBoardCard';
import Select from 'react-select';
import { PiListMagnifyingGlassBold } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Sectors = () => {
    const apiService = new ApiService();
    const [sectorList, setSectorList] = useState(null);
    const [districtList, setDistrictList] = useState(null);
    const [startupList, setStartupList] = useState(null);
    const [dashData, setDashData] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [search, setSearch] = useState(true);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [isShowError, setIsShowError] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    let navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 767px)');

    const searchKeyChange = (event) => {
        setSearchKey(event.target.value);
        // if(searchKey.length>2){
        //     navigate('/startup')
        // }
    };
    const handleChange = (selectedOption) => {
        console.log(selectedOption)
        setSelectedOption(selectedOption);
        fetchSector();
    };
    const handleChangeStartup = (searchKey) => {
        setSearchKey(searchKey);
    };
    const handleSearch = () => {
        setAdvancedSearch(false);
        setSearch(true);
        goToStartup();
    };
    const handleAdvancedSearch = () => {
        setAdvancedSearch(true);
        setSearch(false);
        goToStartup();
    }
    const handleChangeDistrict = (selectedDistrict) => {
        console.log(selectedDistrict)
        setSelectedDistrict(selectedDistrict);
    };
    const goToStartup = () => {
        if (searchKey || selectedOption || selectedDistrict) {
            setIsShowError(false);
            navigate('/Startup', { state: { searchKey: searchKey, sectorSno: selectedOption, districtSno: selectedDistrict } })
        } else {
            setIsShowError(true);
        }
    }
    useEffect(() => {
        // Perform data fetching or any other side effect here
        fetchSector();
        fetchDashCount();
        fetchDistrict();
        fetchStartup();
    }, []); // Empty dependency array means this effect runs only once

    async function fetchSector() {
        var body = { sectorSno: selectedOption?.sectorSno };
        try {
            const response = await apiService.post('get_sector', body);
            if (response && response?.data) {
                setSectorList(response?.data)
                console.log(sectorList)
            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    async function fetchDashCount() {
        var body = {};
        try {
            const response = await apiService.post('get_dashboard_counts', body);
            if (response && response?.data) {
                setDashData(response?.data)
                console.log(dashData)
            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    async function fetchDistrict() {
        var body = {};
        try {
            const response = await apiService.post('search_district', body);
            if (response && response?.data) {
                setDistrictList(response?.data)
                console.log(dashData)
            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    async function fetchStartup() {
        var body = {};
        try {
            const response = await apiService.post('search_entity', body);
            if (response && response?.data) {
                setStartupList(response?.data)
                console.log(dashData)
            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <>
            <div className={!isMobile?"px-5 mx-3":'px-1 mx-2'}>
                <div className="row header-div">
                    <div className=" card-bg-img p-4 round-radius header-div">
                        {/* <ProfileCard /> */}
                        <DashBoardCard dashData={dashData} />
                    </div>
                    {/* <div className="container search-bar px-5">
                        <div className="bg-white textOpacity px-4 py-2" style={{ borderRadius: '2pc', margin: 'auto' }}>
                            <div className="px-2">
                                <div className="d-flex justify-content-between">
                                    {!advancedSearch ? (
                                        <div className="form-group w-100">
                                            <input
                                                type="text"
                                                className="form-control custom-select"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Search By Keyword ..."
                                                onChange={searchKeyChange}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <Select
                                                className="custom-select"
                                                classNamePrefix="custom-select"
                                                value={selectedOption}
                                                onChange={handleChange}
                                                options={sectorList}
                                                getOptionValue={(option) => option?.sectorSno}
                                                getOptionLabel={(option) => option?.sectorName}
                                                isClearable
                                                placeholder="Search By Sector"
                                            />
                                            <Select
                                                className="custom-select"
                                                classNamePrefix="custom-select"
                                                value={selectedDistrict}
                                                onChange={handleChangeDistrict}
                                                options={districtList}
                                                getOptionValue={(option) => option?.districtSno}
                                                getOptionLabel={(option) => option?.districtName}
                                                isClearable
                                                placeholder="Search By District"
                                            />
                                            <div className="form-group w-100">
                                                <input
                                                    type="text"
                                                    className="form-control custom-select"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Search By Keyword ..."
                                                    onChange={searchKeyChange}
                                                />
                                            </div>
                                        </>
                                    )}


                                    <div className='d-flex ' >
                                        <button className="btn " style={{ borderRadius: '10px 0px 0px 10px', background: !search ? 'white' : '#38157c', color: !search ? 'black' : 'white', borderColor: 'black' }} type="button" id="button-addon2" onClick={() => handleSearch()}><FaSearch /></button>
                                        <button className="btn " style={{ borderRadius: '0px 10px 10px 0px', background: advancedSearch ? '#38157c' : 'white', color: advancedSearch ? 'white' : 'black', borderColor: 'black' }} type="button" id="button-addon2" onClick={() => handleAdvancedSearch()}><PiListMagnifyingGlassBold className='h5' /></button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="container search-bar px-1 px-md-5 py-1 py-md-4">
                        {!advancedSearch && isShowError ? (
                            <div className="text-danger">Please Enter Keyword</div>
                        ) : (<div></div>)}
                        {!selectedOption && !selectedDistrict && !searchKey && advancedSearch? (
                            <div className="text-danger">Please Select Any Options</div>
                        ) : (<div></div>)}
                        <div className="bg-white textOpacity px-4 py-2" style={{ borderRadius: '2pc', margin: 'auto' }}>
                            <div className="px-2">

                                <div className="d-flex flex-column flex-md-row justify-content-between">
                                    {!advancedSearch ? (
                                        <>
                                            <div className="form-group w-100 mb-3 mb-md-0">
                                                <input
                                                    type="text"
                                                    className="form-control custom-select"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Search By Keyword ..."
                                                    onChange={searchKeyChange}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <Select
                                                className="custom-select mb-3 mb-md-0"
                                                classNamePrefix="custom-select"
                                                value={selectedOption}
                                                onChange={handleChange}
                                                options={sectorList}
                                                getOptionValue={(option) => option?.sectorSno}
                                                getOptionLabel={(option) => option?.sectorName}
                                                isClearable
                                                placeholder="Search By Sector"
                                            />
                                            <Select
                                                className="custom-select mb-3 mb-md-0"
                                                classNamePrefix="custom-select"
                                                value={selectedDistrict}
                                                onChange={handleChangeDistrict}
                                                options={districtList}
                                                getOptionValue={(option) => option?.districtSno}
                                                getOptionLabel={(option) => option?.districtName}
                                                isClearable
                                                placeholder="Search By District"
                                            />
                                            <div className="form-group w-100 mb-3 mb-md-0">
                                                <input
                                                    type="text"
                                                    className="form-control custom-select"
                                                    id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Search By Keyword ..."
                                                    onChange={searchKeyChange}
                                                />
                                            </div>
                                        </>
                                    )}

                                    <div className='d-flex '>
                                        <button className="btn me-2 mb-3 mb-md-0" style={{ borderRadius: '10px', background: !search ? 'white' : '#38157c', color: !search ? 'black' : 'white', borderColor: 'black' }} type="button" id="button-addon2" onClick={() => handleSearch()}><FaSearch /></button>
                                        <button className="btn me-2 mb-3 mb-md-0" style={{ borderRadius: '10px', background: advancedSearch ? '#38157c' : 'white', color: advancedSearch ? 'white' : 'black', borderColor: 'black' }} type="button" id="button-addon2" onClick={() => handleAdvancedSearch()}><PiListMagnifyingGlassBold className='h5' /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <br></br>
                {/* <SearchHeader /> */}
                <SectorCard sectorData={sectorList} />
            </div>
        </>

    );
};

export default Sectors;