class ApiService {
  constructor() {
      // Read the isLive value from the environment variable
      this.isLive = true; // Assuming the value in the env file is a string representation of a boolean
      // Set the baseURL based on the isLive value
    //   this.baseURL = this.isLive ? 'http://3.109.194.217:8011/api/stn/' : 'http://192.168.1.25:8011/api/stn/'; 
      this.baseURL = this.isLive ? 'https://sector.startuptn.in:9000/api/stn/' : 'http://192.168.1.20:8011/api/stn/'; 
  }

  async get(endpoint, params = {}) {
      try {
          const queryString = new URLSearchParams(params).toString();
          const response = await fetch(`${this.baseURL}${endpoint}?${queryString}`);
          const data = await response.json();
          return data;
      } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
      }
  }

  async post(endpoint, body = {}) {
      try {
          const response = await fetch(`${this.baseURL}${endpoint}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(body)
          });
          const data = await response.json();
          return data;
      } catch (error) {
          console.error('Error posting data:', error);
          throw error;
      }
  }

  // Add methods for other HTTP verbs (PUT, DELETE, etc.) as needed
}

export default ApiService;
