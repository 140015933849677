import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { GiAerodynamicHarpoon } from "react-icons/gi";
import { FaLocationArrow } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import ApiService from '../../common/ApiService';
import { Autocomplete, TextField } from '@mui/material';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(0),
        },

    },
}));
export default function AdvancedSearchBox(props) {
    console.log(props)
    const apiService = new ApiService();
    const classes = useStyles();
    const [expanded, setExpanded] = useState(true);
    const [selectedValue, setSelectedValue] = useState(null);
    const handleAutocompleteChange = (event, newValue) => {
        setSelectedValue(newValue);
        console.log(newValue)
        props.sectorFunction(newValue);
        // fetchSector(newValue)

    };
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const isMobile = useMediaQuery('(max-width: 767px)');


    useEffect(() => {
        // fetchSector(props?.sectorSno);
    }, []);
    useEffect(() => {
        console.log("sectorList")

        // sectorList.forEach((item) => {
        //     item.filtered = false;
        // });
        // setSectorList(sectorList)
        // console.log('data',sectorList)


        // fetchSector(null);
    }, [props?.sectorSno == null]);




    return (
        <>
            <div className="card shadow p-3 mx-2" style={{ border: '1px solid #f4f5f7' }} >
                <div onClick={handleExpandClick}> <b>Sectors </b></div>
                {expanded ? (<hr></hr>) : (null)}
                <Autocomplete
                    onChange={handleAutocompleteChange}
                    value={selectedValue} // Set the value prop to the selected value
                    options={props?.sectorList}
                    getOptionLabel={(option) => option?.sectorName} // Specify which property to use as the label
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search By Sector"
                            variant="outlined"
                            sx={{
                                '& fieldset': {
                                    borderColor: '#f2f3f5', // Change border color to light grey
                                },
                                '&:hover fieldset': {
                                    borderColor: '#f2f3f5', // Add hover effect
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#f2f3f5', // Add focus effect
                                },
                                pb: 2, // Add padding bottom
                            }}
                        />
                    )}
                />
                <div className={classes.root}>
                    {/* <Button className='text-start' variant="contained" color="primary" onClick={handleExpandClick} fullWidth>
                        Sectors
                    </Button> */}
                    {!isMobile && (

                        <Collapse in={expanded}>
                            {props?.sectorList?.map((item, index) => (
                                <div className="d-flex justify-content-between px-1 py-2 m-1" onClick={() => props?.sectorFunction(item)}
                                    style={{ background: item?.filtered ? '#f8e7b9' : 'transparent', borderRadius: '5px' }}
                                    key={item?.sectorSno} >
                                    <div className="d-flex">
                                        <GiAerodynamicHarpoon />
                                        <div className='px-3'>{item?.sectorName}</div>
                                    </div>
                                    <FaLocationArrow className='pt-1' />
                                </div>
                            ))}


                        </Collapse>)}
                </div>
            </div>

        </>
    );
}