import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { MdLanguage } from 'react-icons/md';
import { CiMobile1 } from 'react-icons/ci';
import { HiOutlineMail } from 'react-icons/hi';
import bglogo from '../assets/user-card/stn-avtar.png'

function ViewStartupDialoge({ open, handleClose, selectedItem }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex py-2">
              <div>
                <img className="rounded-circle" src={selectedItem?.logoPath} width={60} height={60} alt=""
                  onError={(e) => {
                    e.target.src = bglogo; // Replace with fallback image when error occurs
                    e.target.onerror = null; // Prevent infinite loop in case fallback image also fails
                  }} />
              </div>
              <div>
                <p className="px-4 h5"><b>{selectedItem?.entityName}</b></p>
                <p className="px-4 text-secondary">{selectedItem?.districtName}</p>
              </div>
            </div>
            <div>
              <p className="px-4 h6"><b>{selectedItem?.sectorName}</b></p>
              <div className="px-4 text-secondary">Sector</div>

            </div>
          </div>
          <p><a href={selectedItem?.primaryWebsite}><MdLanguage /> {selectedItem?.primaryWebsite}</a></p>
        </>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="p-1" style={{ fontSize: '18px' }}>
          {selectedItem?.entityUsp}
          <div className="d-flex justify-content-between">
            <div className="mt-2">
              MOBILE
              <div className="h5 text-dark"><CiMobile1 />{selectedItem?.primaryPhoneNo?.length > 1 ? selectedItem?.primaryPhoneNo : 'NA'}</div>
            </div>
            <div className="mt-2">
              EMAIL
              <div className="h5 text-dark"><HiOutlineMail />{selectedItem?.primaryMailId?.length > 1 ? selectedItem?.primaryMailId : 'NA'}</div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default ViewStartupDialoge;
