import { useEffect, useState } from "react";
import ApiService from "../../common/ApiService";
import { RotateLoader } from "react-spinners";

export default function Mentors({ sectorSno }) {
    const apiService = new ApiService();

    const [mentorsList, setMentorsList] = useState(null);
    console.log(sectorSno)
    useEffect(() => {
        getMentors();
    }, [sectorSno])

    async function getMentors() {
        setMentorsList(null)
        var body = { 'sectorSno': sectorSno, 'skip': 0, "limit": 10 };
        console.log(body)
        try {
            const response = await apiService.post('get_mentors', body);
            console.log(response)
            if (response && response?.data) {
                setMentorsList(response?.data)
            } else {
                setMentorsList([])
            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <>
            <br></br>
            {(mentorsList != null && mentorsList?.length > 0) ?(<div className="row">
                {mentorsList?.map((item, index) => (
                    <div className="col-md-6" key={index}>
                        <div className="card profile-card-2">

                            <div className="card-body">
                                <div className=" d-flex ">
                                    <div>
                                        <img src={item?.profile_img?.img?item?.profile_img?.img:'/assets/images.png'}
                                         onError={(e) => {
                                            e.target.onerror = null; // Prevents infinite loop if fallback also fails
                                            e.target.src = '/assets/images.png'; // Fallback image path
                                          }} alt="profile-image" width={'50'} height={'50'} className="profile rounded-circle" />
                                    </div>
                                    <div className="w-100 d-flex justify-content-between">
                                        <div className="mx-3">
                                            <h5 className="">{item?.mentor_name}
                                                <div className="h6 text-secondary p-1">{item?.experience} Year Experience</div>
                                            </h5>
                                        </div>
                                        <div className="mr-5">
                                            <div>{item?.email}</div>
                                            <div className="p-1">{item?.mobile}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-text py-2">
                                    {item?.profile_desc}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>):(mentorsList && mentorsList.length == 0) ? (<div className="text-center my-4">
                <img src="/assets/no data/images.png" alt="" width={'300px'} />
                <div className="h6 text-secondary">Mentors Not Found !</div>
            </div>) : (<div className="my-5 text-center ">
                <br></br>
                <RotateLoader color="#212529" />
            </div>)}
        </>
    );
}