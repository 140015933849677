

export default function DashBoardCard(props) {
    const dashData = props.dashData;
    return (
        <>
            <div className="card col-12 col-sm-6 col-md-4  round-radius" style={{ borderRadius: '20px' }}>
                <   div className="text-center ">
                    <img src='/assets/stn-avtar.png' className="rounded-circle" width={100} height={100} alt="Profile Image" />
                    <div className="card-body text-center">
                        <h5 className="card-title h1">StartupTN SECTORS</h5>

                        <br></br>
                        <div className="row">
                            <div className="col-4 border-top p-2">
                                <div>
                                    <b className="h2">{dashData?.sectorCount}</b>
                                </div>
                                <p className='text-secondary h6'>SECTORS</p>
                            </div>
                            <div className="col-4 border p-2">
                                <div>
                                    <b className="h2">{dashData?.startupCount - 1} +</b>
                                </div>
                                <p className='text-secondary'>STARTUPS</p>
                            </div>
                            <div className="col-4 border-top p-2">
                                <div>
                                    <b className="h2">{dashData?.districtCount}</b>
                                </div>
                                <p className='text-secondary'>DISTRICT</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}