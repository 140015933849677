import { Grid } from "@mui/material";
import Startup from "./Startup";
import AdvancedSearchBox from "../view-sector/AdvancedSearchBox";
import AdvancedSearchDistrict from "../view-sector/AdvancedSearchDistrict";
import { useEffect, useState } from "react";
import ApiService from "../../common/ApiService";
import { useLocation } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function StartupHome() {
    const apiService = new ApiService();
    const location = useLocation();
    const [sectorData, setSectorData] = useState({ "sectorSno": location?.state?.sectorSno });
    const [district, setDistrict] = useState({ districtSno: location?.state?.districtSno });
    const [searchKey, setSearchKey] = useState(location?.state?.searchKey);
    const [sectorList, setSectorList] = useState([]);
    const [districtList, setDistrictList] = useState(null);
    const [startupList, setStartupList] = useState([]);

    const [params, setParams] = useState({
        "sectorSno": location?.state?.sectorSno?.sectorSno,
        "districtSno": location?.state?.districtSno?.districtSno,
        "searchKey": searchKey,
        "sectorName": location?.state?.sectorSno?.sectorName,
        "districtName": location?.state?.districtSno?.districtName
    });
    const isMobile = useMediaQuery('(max-width: 767px)');

    useEffect(() => {
        console.log(sectorData)
        console.log(params)
        fetchSector();
        fetchDistrict();
        fetchStartup();

    }, [params]);

    const sectorFunction = async (item) => {
        setSectorData(item);
        setParams(prevParams => ({
            ...prevParams,  // Keep existing values
            ...item    // Update with new values
        }));

        console.log(params)

    };
    const districtFunction = async (district) => {
        setDistrict(district)
        setParams(prevParams => ({
            ...prevParams,  // Keep existing values
            ...district    // Update with new values
        }));
        console.log(params)

    };
    const keyFunction = async (value) => {
        console.log(value)
        setSearchKey(value)
        setParams(prevParams => ({
            ...prevParams,  // Keep existing values
            ...value    // Update with new values
        }));
        console.log(params)
    }


    async function fetchSector() {
        console.log(params)
        var body = params;
        try {
            const response = await apiService.post('get_sector_selected', body);
            if (response && response?.data) {
                setSectorList(response?.data)
                console.log(sectorList)
            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    async function fetchDistrict() {
        var body = params;
        try {
            const response = await apiService.post('get_district_selected', body);
            if (response && response?.data) {
                setDistrictList(response?.data)
                console.log(response?.data)
            }
            //   setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const fetchStartup = async () => {
        var body = { 'skip': 0, 'limit': 3, 'entityName': params?.searchKey, 'sectorSno': params?.sectorSno, 'districtSno': params?.districtSno ?? null };

        console.log(body)
        try {
            const response = await apiService.post('get_startup_by_sector', body);
            console.log(response)
            if (response && response?.data) {
                setStartupList(response?.data)
                console.log(startupList)
            } else {
                setStartupList([])

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    return (
        <>
            {!isMobile ? (
                <Grid container spacing={1} >
                    <Grid item xs={12} sm={6} md={2}>
                        <AdvancedSearchBox sectorList={sectorList} sectorFunction={sectorFunction} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                        <Startup params={params} startupList={startupList} sectorFunction={sectorFunction}
                            districtFunction={districtFunction} keyFunction={keyFunction} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <AdvancedSearchDistrict districtFunction={districtFunction} districtList={districtList} />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={1} >
                    <Grid item xs={12} sm={6} md={2}>
                        <AdvancedSearchBox sectorList={sectorList} sectorFunction={sectorFunction} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <AdvancedSearchDistrict districtFunction={districtFunction} districtList={districtList} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                        <Startup params={params} startupList={startupList} sectorFunction={sectorFunction}
                            districtFunction={districtFunction} keyFunction={keyFunction} />
                    </Grid>

                </Grid>
            )}
        </>
    );
}